@import 'Variables';
@import 'Fonts';
@import 'Mixins';
@import 'General';
@import 'Content';


.breadcrumbs,
button,
.button,
.sidebar,
.topbar {
  display: none;
}

.site.content-page {
  padding-left: 0;
}

.site__main {
  margin-left: 0;
}

.document-accordion__entry {
  padding: 20px;
}

.document-accordion__fold-icon {
  display: none;
}

.dashboard__user-information {
  display: none;
}

.hidden-md,
.hidden-sm,
.hidden.xs {
  display: none
}

a {
  color: $brand-primary;
  &:visited {
    color: $brand-primary;
  }
}