@charset "UTF-8";
/**
 * @license
 * MyFonts Webfont Build ID 3234414, 2016-06-17T08:40:39-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: HelveticaLT-Bold by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/helvetica/bold/
 * Copyright: Part of the digitally encoded machine readable outline data for producing the Typefaces provided is copyrighted (c) 1985, 1987, 1989, 1990, 1997, 1998, 1999, 2003 Linotype Library GmbH, www.linotype.com. All rights reserved. This software is the property 
 * Licensed pageviews: 500,000
 * 
 * Webfont: HelveticaLTPro-Light by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/helvetica/pro-light/
 * Copyright: Part of the digitally encoded machine readable outline data for producing the Typefaces provided is copyrighted &#x00A9; 2008 Linotype GmbH, www.linotype.com. All rights reserved. This software is the property of Linotype GmbH, and may not be reproduced, 
 * Licensed pageviews: 250,000
 * 
 * 
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=3234414
 * 
 * © 2016 MyFonts Inc
*/
/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/315a6e");
@font-face {
  font-family: 'HelveticaLT-Bold';
  src: url("/_Resources/Static/Packages/GesagtGetan.SafetylowsAt/Fonts/Helvetica/315A6E_0_0.eot");
  src: url("/_Resources/Static/Packages/GesagtGetan.SafetylowsAt/Fonts/Helvetica/315A6E_0_0.eot?#iefix") format("embedded-opentype"), url("/_Resources/Static/Packages/GesagtGetan.SafetylowsAt/Fonts/Helvetica/315A6E_0_0.woff2") format("woff2"), url("/_Resources/Static/Packages/GesagtGetan.SafetylowsAt/Fonts/Helvetica/315A6E_0_0.woff") format("woff"), url("/_Resources/Static/Packages/GesagtGetan.SafetylowsAt/Fonts/Helvetica/315A6E_0_0.ttf") format("truetype"); }

@font-face {
  font-family: 'HelveticaLTPro-Light';
  src: url("/_Resources/Static/Packages/GesagtGetan.SafetylowsAt/Fonts/Helvetica/315A6E_1_0.eot");
  src: url("/_Resources/Static/Packages/GesagtGetan.SafetylowsAt/Fonts/Helvetica/315A6E_1_0.eot?#iefix") format("embedded-opentype"), url("/_Resources/Static/Packages/GesagtGetan.SafetylowsAt/Fonts/Helvetica/315A6E_1_0.woff2") format("woff2"), url("/_Resources/Static/Packages/GesagtGetan.SafetylowsAt/Fonts/Helvetica/315A6E_1_0.woff") format("woff"), url("/_Resources/Static/Packages/GesagtGetan.SafetylowsAt/Fonts/Helvetica/315A6E_1_0.ttf") format("truetype"); }

html {
  overflow-x: hidden; }

a:hover {
  text-decoration: underline; }

.float--left {
  float: left; }

.float--right {
  float: right; }

.container-fluid {
  margin-left: 0; }

.site {
  padding-left: 300px; }
  .site__header {
    overflow: hidden; }
  .site__main {
    max-width: 1070px;
    margin-left: 130px; }
  .site__footer {
    border-top: 1px solid #003d8f;
    padding: 40px 0 40px 0;
    margin-top: 100px;
    margin-left: -20px;
    padding-left: 20px;
    width: calc(100% + 40px); }
  .site__content {
    margin-top: 50px; }

.footer__inner {
  max-width: 1070px;
  margin-left: 130px; }

.footer__disclaimer {
  color: #003d8f;
  font-size: 14px; }

.footer__logos {
  height: 50px; }

.no-navigation .footer__inner {
  margin: 0 auto 0 auto; }

.no-navigation.site {
  padding-left: 0; }

.no-navigation .site__main {
  margin-left: auto;
  margin-right: auto; }

.logo {
  display: block; }

.flex-container {
  display: flex;
  flex-direction: column;
  height: 100%; }

.no-padding--left {
  padding-left: 0; }

.no-padding--right {
  padding-right: 0; }

.flash-messages {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1000; }
  .flash-messages .flashmessages-ok,
  .flash-messages .flashmessages-error {
    display: block;
    margin-bottom: 5px;
    color: white;
    position: relative;
    background-color: #00a338;
    padding: 20px;
    animation: slideIn 1s; }

@keyframes slideIn {
  from {
    top: -100px; }
  to {
    top: 0px; } }
  .flash-messages .flashmessages-error {
    background-color: #ff0000; }
  .flash-messages ul,
  .flash-messages li {
    margin: 0;
    padding: 0;
    list-style-type: none; }

.topbar {
  border-bottom: 1px solid #dddedf; }
  .topbar__inner {
    max-width: 1070px;
    margin: 0 auto 0 150px;
    height: 50px; }
  .topbar__link {
    display: block;
    float: left;
    line-height: 49px;
    color: #2e2e2e;
    border: 1px solid #dddedf;
    border-left: none;
    border-bottom: none;
    padding: 0 20px 0 40px;
    background-repeat: no-repeat;
    background-position: center left 10px; }
    .topbar__link:first-child {
      border-left: 1px solid #dddedf; }
    .topbar__link:hover {
      background-color: #e2e8f1; }
    .topbar__link--dashboard {
      background-image: url("/_Resources/Static/Packages/GesagtGetan.SafetylowsAt/Images/Layout/StarBlack.svg");
      background-size: 15px 15px; }
      .topbar__link--dashboard:hover {
        background-image: url("/_Resources/Static/Packages/GesagtGetan.SafetylowsAt/Images/Layout/StarBlue.svg"); }
    .topbar__link--signout {
      background-image: url("/_Resources/Static/Packages/GesagtGetan.SafetylowsAt/Images/Layout/UnlockGrey.svg");
      background-size: 16px 15px; }
      .topbar__link--signout:hover {
        background-image: url("/_Resources/Static/Packages/GesagtGetan.SafetylowsAt/Images/Layout/UnlockBlue.svg"); }
    .topbar__link--profile {
      background-image: url("/_Resources/Static/Packages/GesagtGetan.SafetylowsAt/Images/Layout/PersonGrey.svg");
      background-size: 12px 15px; }
      .topbar__link--profile:hover {
        background-image: url("/_Resources/Static/Packages/GesagtGetan.SafetylowsAt/Images/Layout/PersonBlue.svg"); }
    .topbar__link input[type="submit"] {
      background: none;
      border: none;
      outline: none;
      padding: 0;
      display: inline; }
    .topbar__link:hover {
      text-decoration: none; }
  .topbar__link-container {
    float: right; }
  .topbar .search-field .input__field {
    border-right: 0;
    border-top: 0;
    border-bottom: 0; }
  .topbar__left {
    float: left;
    width: 50%; }

.search-field .input--with-placeholder {
  margin-top: 0; }

.more-link {
  background-repeat: no-repeat;
  background-image: url("/_Resources/Static/Packages/GesagtGetan.SafetylowsAt/Images/Layout/Arrow.svg");
  background-position: center right;
  padding-right: 25px;
  font-family: 'HelveticaLTPro-Light';
  font-weight: normal;
  font-style: normal;
  font-size: 16px; }

.breadcrumbs {
  padding: 0;
  max-width: 1070px;
  margin: 15px auto 0 132px; }
  .breadcrumbs li {
    display: inline-block;
    list-style-type: none;
    font-size: 12px; }
    .breadcrumbs li a {
      color: #2e2e2e; }
    .breadcrumbs li:after {
      content: '>'; }
    .breadcrumbs li:last-child:after {
      content: ''; }

.scroll-to-top {
  display: none;
  position: fixed;
  right: 20px;
  bottom: 20px;
  background: #13579d;
  width: 50px;
  height: 50px;
  line-height: 61px;
  color: white;
  font-size: 30px;
  text-align: center;
  cursor: pointer; }

body {
  background: white;
  color: #2e2e2e; }

.site {
  font-family: 'Arial';
  font-weight: normal;
  font-style: normal;
  font-size: 15px;
  color: #2e2e2e; }

.node--narrow {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto; }

.node {
  margin-bottom: 70px; }
  .node--headline-box {
    text-align: center;
    border-bottom: 5px solid #dddedf;
    padding-bottom: 30px;
    margin-bottom: 50px; }
  .node--headline {
    text-align: center;
    margin-bottom: 20px; }
    .node--headline--left {
      text-align: left; }
    .node--headline--right {
      text-align: right; }
  .node__main-headline {
    margin-bottom: 0; }
  .node__sub-headline {
    margin-bottom: 0;
    margin-top: -5px; }

p {
  margin: 5px 0 15px 0; }

h1 {
  font-family: 'HelveticaLT-Bold';
  font-weight: normal;
  font-style: normal;
  font-size: 40px;
  color: #003d8f;
  margin: 20px 0 20px 0; }

h2, h3 {
  color: #13579d;
  font-family: 'HelveticaLTPro-Light';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  margin: 15px 0 10px 0; }

h3 {
  font-size: 22px; }

h4, h5 {
  color: #2e2e2e;
  margin-bottom: 0; }

.text table {
  max-width: 100%;
  overflow-x: auto;
  display: block; }

.text ul {
  list-style-type: square;
  line-height: 2; }

.text th {
  font-weight: bold;
  background: #dddedf;
  border-top: 1px solid #c7c8ca; }

.text tr {
  border-left: 1px solid #c7c8ca;
  background: #eeeeef; }

.text tr:nth-child(even) {
  background: #fff; }

.text th, .text td {
  padding: 10px;
  border-right: 1px solid #c7c8ca;
  border-bottom: 1px solid #c7c8ca; }

.text-with-image__headline {
  margin-top: -3px; }

.gallery__image {
  margin-bottom: 40px;
  display: block; }

.youtube {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto; }
  .youtube__video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden; }
    .youtube__video-container iframe,
    .youtube__video-container object,
    .youtube__video-container embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }

.document-accordion {
  max-width: 800px;
  margin: 0 auto 0 auto; }
  .document-accordion__section {
    margin-bottom: 30px;
    transition: height 0.5s;
    min-height: 31px; }
  .document-accordion .js-document-accordion__section--folded .document-accordion__entries {
    overflow: hidden; }
  .document-accordion .js-document-accordion__section--folded .document-accordion__fold-icon {
    transform: rotate(90deg); }
  .document-accordion__entry {
    display: block;
    padding: 10px;
    padding-right: 0;
    border-bottom: 1px solid #dddedf;
    color: #2e2e2e; }
    .document-accordion__entryfocus, .document-accordion__entry:hover {
      text-decoration: none;
      color: #2e2e2e;
      background: #dddedf; }
  .document-accordion__section-header {
    cursor: pointer;
    background: #dddedf;
    padding: 5px 10px;
    margin-bottom: 10px; }
    .document-accordion__section-header:hover {
      background: #eaebeb; }
  .document-accordion__section-title {
    color: #2e2e2e;
    font-size: 22px;
    margin: 0;
    float: left; }
  .document-accordion__fold-icon {
    position: relative;
    top: 5px;
    display: block;
    float: right;
    transform: rotate(-90deg);
    transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1); }
  .document-accordion__favourite-container {
    float: right; }
    .document-accordion__favourite-container--add .js-favourite-form--delete {
      display: none; }
    .document-accordion__favourite-container--delete .js-favourite-form--add {
      display: none; }
  .document-accordion__file-description-container {
    float: left; }
  .document-accordion__action-button {
    background-color: transparent;
    border: 0;
    outline: 0;
    text-indent: -500px;
    background-repeat: no-repeat;
    overflow: hidden; }
  .document-accordion .favourite-button {
    background-image: url("/_Resources/Static/Packages/GesagtGetan.SafetylowsAt/Images/Layout/StarGrey.svg");
    background-size: 20px 18px;
    background-position: center center;
    background-repeat: no-repeat;
    height: 25px;
    width: 40px; }
    .document-accordion .favourite-button--on {
      background-image: url("/_Resources/Static/Packages/GesagtGetan.SafetylowsAt/Images/Layout/StarBlack.svg"); }
  .document-accordion__file-title {
    text-overflow: ellipsis; }

.breadcrumbs,
button,
.button,
.sidebar,
.topbar {
  display: none; }

.site.content-page {
  padding-left: 0; }

.site__main {
  margin-left: 0; }

.document-accordion__entry {
  padding: 20px; }

.document-accordion__fold-icon {
  display: none; }

.dashboard__user-information {
  display: none; }

.hidden-md,
.hidden-sm,
.hidden.xs {
  display: none; }

a {
  color: #003d8f; }
  a:visited {
    color: #003d8f; }
